<template>
   <div class="d-flex-inline">
      <!-- @change="processSelectedFile"  -->
      <input type="file" style="display: none" ref="fileInput" accept="*/*" 
         @change="processSelectedFile"/>

      <v-btn color="teal" dark
         class="text-none mr-2 mb-2 px-2"
         @click="resultContent='';startPickFile('modal1')">
         <v-icon class="mr-2">mdi-import</v-icon>
         Import Excel HĐLĐ
      </v-btn>
      <v-btn color="teal" dark
         class="text-none mr-2 mb-2 px-2"
         @click="resultContent='';startPickFile('modal2')">
         <v-icon class="mr-2">mdi-import</v-icon>
         Import Excel hồ sơ thiếu
      </v-btn>
      <!-- dialog import user modal 1 - HĐLĐ -->
      <v-dialog v-model="dialogImportModal1"
         fullscreen
         hide-overlay persistent width="1500">
         <v-card>
            <v-card-title class="py-0 pr-1" style="background-color:teal;color:white;">
               Import thông tin nhân viên và hợp đồng lao động
               <v-spacer/>
               <v-btn dark color="white" icon @click='dialogImportModal1=false;'> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-card-text class='pa-1' style="min-height: 70vh;">
               <div class="d-flex px-2 py-1">
                  <v-spacer/>
                  <v-btn :disabled='!hasModal1Selected' @click="saveModal1SelectedItem" 
                     color="primary" class="text-none" :loading='onSaving'> 
                     Lưu {{selectedUserModal1.length}} nhân viên được chọn</v-btn>
               </div>
               <div v-if='resultContent' class="px-2 py-1" style="background-color:#444444;color:white;font-size:13px;line-height:1.2;max-height:90px;;overflow-y:auto;">
                  <p v-html='resultContent'></p>
               </div>
               <v-data-table
                  v-model="selectedUserModal1"
                  :headers="headersModal1"
                  :items="importUsersModal1"
                  loading-text="Loading... Please wait"
                  no-data-text="Chưa có nhân viên nào"
                  item-key="user_code"
                  :mobile-breakpoint="0"
                  :items-per-page="pageSize"
                  show-select dense flat
                  class="elevation-0 tableImport"
                  :search="searchString"
                  :page.sync="activePage"
                  @page-count="pageCount = $event"
                  style="font-size:12px;"
                  hide-default-footer>
                  <template v-slot:item.user_code="{ item }">
                     <div v-if="item._id">
                        {{item.user_code}}
                     </div>
                     <div v-else style="color:red">
                        <i>Chưa có</i> <b>{{item.user_code}}</b>
                     </div>
                  </template>
                  <template v-slot:item.departmentObject="{ item }">
                     <div v-if="item.departmentObject">
                        {{item.departmentObject.name}}
                     </div>
                     <div v-else style="color:red">
                        <i>Chưa có</i> <b>{{item.department_name}}</b>
                     </div>
                  </template>
                  <template v-slot:item.vanbans="{ item }">
                     <div v-for="(vanban, index) in item.vanbans" :key="index" style="font-size:12px;">
                        <div>
                           <span class="mr-1">Tên: <b>{{vanban.ten}}</b> </span> <span> <i>{{vanban.loai}}</i> </span>
                        </div>
                        <div>
                           <span> Ký {{vanban.ngayky?$moment(vanban.ngayky).format('DD/MM/YYYY'):''}} </span>
                           <span class="mr-1"> Thời hạn: <b>{{vanban.thoihan}}</b> </span>
                        </div>
                        <div>
                           {{vanban.ngayhieuluc?$moment(vanban.ngayhieuluc).format('DD/MM/YYYY'):''}} - {{vanban.ngayhethan?$moment(vanban.ngayhethan).format('DD/MM/YYYY'):''}}
                        </div>
                     </div>
                  </template>
               </v-data-table>
               <div v-if="pageCount>1" class="text-center pt-2">
                  <v-pagination v-model="activePage" :length="pageCount"></v-pagination>
               </div>
            </v-card-text>
         </v-card>
      </v-dialog>

      <!-- dialog import user modal 2 - Hồ sơ thiếu -->
      <v-dialog v-model="dialogImportModal2"
         fullscreen
         hide-overlay persistent width="1500">
         <v-card>
            <v-card-title class="py-0 pr-1" style="background-color:teal;color:white;">
               Import Thông tin hồ sơ thiếu
               <v-spacer/>
               <v-btn dark color="white" icon @click='dialogImportModal2=false;'> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-card-text class='pa-1' style="min-height: 70vh;">
               <div class="d-flex px-2 py-1">
                  <v-spacer/>
                  <v-btn :disabled='!hasModal2Selected' @click="saveModal2SelectedItem" 
                     color="primary" class="text-none" :loading='onSaving'> 
                     Lưu {{selectedUserModal2.length}} nhân viên được chọn</v-btn>
               </div>
               <div v-if='resultContent' class="px-2 py-1" style="background-color:#444444;color:white;font-size:13px;line-height:1.2;max-height:90px;;overflow-y:auto;">
                  <p v-html='resultContent'></p>
               </div>
               <v-data-table
                  v-model="selectedUserModal2"
                  :headers="headersModal2"
                  :items="importUsersModal2"
                  loading-text="Loading... Please wait"
                  no-data-text="Chưa có nhân viên nào"
                  item-key="user_code"
                  :mobile-breakpoint="0"
                  :items-per-page="pageSize"
                  show-select dense flat
                  class="elevation-0"
                  :search="searchString"
                  :page.sync="activePage"
                  @page-count="pageCount = $event"
                  style="font-size:12px;"
                  hide-default-footer>
                  <template v-slot:item.user_code="{ item }">
                     <div v-if="item._id">
                        {{item.user_code}}
                     </div>
                     <div v-else style="color:red">
                        <i>Chưa có</i> <b>{{item.user_code}}</b>
                     </div>
                  </template>
                  <template v-slot:item.check_donxinviec="{ item }">
                     {{item.check_donxinviec?'X':''}}
                  </template>
                  <template v-slot:item.check_soyeulilich="{ item }">
                     {{item.check_soyeulilich?'X':''}}
                  </template>
                  <template v-slot:item.check_giaykhamsuckhoe="{ item }">
                     {{item.check_giaykhamsuckhoe?'X':''}}
                  </template>
                  <template v-slot:item.check_giaykhaisinh="{ item }">
                     {{item.check_giaykhaisinh?'X':''}}
                  </template>
                  <template v-slot:item.check_bangcap="{ item }">
                     {{item.check_bangcap?'X':''}}
                  </template>
                  <template v-slot:item.check_cmnd="{ item }">
                     {{item.check_cmnd?'X':''}}
                  </template>
                  <template v-slot:item.check_sohokhau="{ item }">
                     {{item.check_sohokhau?'X':''}}
                  </template>
                  <template v-slot:item.check_anh34="{ item }">
                     {{item.check_anh34?'X':''}}
                  </template>
               </v-data-table>
               <div v-if="pageCount>1" class="text-center pt-2">
                  <v-pagination v-model="activePage" :length="pageCount"></v-pagination>
               </div>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
let XLSX = require("xlsx")
import { mapGetters } from "vuex"
export default {
   props: ['users', 'departments'],
   computed: {
      ...mapGetters({
         token: "token",
         role: "role",
      }),
      hasModal1Selected () {
         return this.selectedUserModal1&&this.selectedUserModal1.length>0
      },
      hasModal2Selected () {
         return this.selectedUserModal2&&this.selectedUserModal2.length>0
      }
   },
   data () {
      return {
         importFileModal: "",
         resultContent: '',
         importUsersModal1: [],
         selectedUserModal1: [],
         headersModal1: [
            { text: "Mã NV", align: "start", sortable: true, value: "user_code", width: 100 },
            { text: "Tên", align: "start", sortable: true, value: "fullname" },
            { text: "Ngày vào làm", align: "start", sortable: true, value: "ngay_vaolam" },
            { text: "Công ty", align: "start", sortable: true, value: "congty" },
            { text: "Khối", align: "start", sortable: true, value: "khoi" },
            { text: "Phòng ban", align: "start", sortable: true, value: "departmentObject" },
            { text: "Chức vụ", align: "start", sortable: true, value: "chucvu" },
            { text: "Vị trí", align: "start", sortable: true, value: "position" },
            { text: "Văn bản", align: "start", sortable: true, value: "vanbans", width: 220 },
         ],
         dialogImportModal1: false,
         pageCount: 0,
         activePage: 1,
         pageSize: 10,
         searchString: '',
         onSaving: false,
         dialogImportModal2: false,
         selectedUserModal2: [],
         importUsersModal2: [],
         headersModal2: [
            { text: "Mã NV", align: "start", sortable: true, value: "user_code", width: 100 },
            { text: "Tên", align: "start", sortable: true, value: "fullname" },
            { text: "Đơn xin việc", align: "start", sortable: true, value: "check_donxinviec" },
            { text: "Sơ yếu lý lịch", align: "start", sortable: true, value: "check_soyeulilich" },
            { text: "Giấy khám sức khỏe", align: "start", sortable: true, value: "check_giaykhamsuckhoe" },
            { text: "Giấy khai sinh", align: "start", sortable: true, value: "check_giaykhaisinh" },
            { text: "Bằng cấp", align: "start", sortable: true, value: "check_bangcap" },
            { text: "CMND", align: "start", sortable: true, value: "check_cmnd" },
            { text: "Hộ khẩu", align: "start", sortable: true, value: "check_sohokhau" },
            { text: "3 ảnh 3x4", align: "start", sortable: true, value: "check_anh34" },
         ],
      }
   },
   methods: {
      startPickFile(modal) {
         this.importFileModal = modal
         this.$refs.fileInput.click();
      },
      async processSelectedFile () {
         var self = this
         let file = this.$refs.fileInput.files ? this.$refs.fileInput.files[0] : null;
         // let workbook = XLSX.readFile(this.file);
         if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
               const bstr = e.target.result;
               const wb = XLSX.read(bstr, { type: 'binary' });
               const wsname = wb.SheetNames[0];
               const ws = wb.Sheets[wsname];
               const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
               self.processExcelData(data)
            }
            reader.readAsBinaryString(file);
         } else {
            console.log('No file selected')
         }
         this.$refs.fileInput.value = ""
      },
      processExcelData (data) {
         // thong tin + HDLD
         if (this.importFileModal == 'modal1') {
            this.importUsersModal1 = []
            data.forEach((row,index) => {
               if(row.length>0&&index>0){
                  let vanban = null
                  if (row.length>9) {
                     vanban = {
                        ten: row.length>9?row[9]:'',
                        loai: row.length>10?row[10]:'',
                        thoihan: row.length>11?row[11]:'',
                        ngayky: row.length>12?this.$moment(row[12], 'DD/MM/YYYY').toDate():null,
                        ngayhieuluc: row.length>13?this.$moment(row[13], 'DD/MM/YYYY').toDate():null,
                        ngayhethan: row.length>14?this.$moment(row[14], 'DD/MM/YYYY').toDate():null,
                     }
                  }
                  let department_name = row.length>6?row[6]:''
                  let departmentObject = this.departments.find(d => d.name_code == department_name.toLowerCase().trim())

                  let user_code = row.length>1?row[1]:''
                  let matchUser = null
                  if (user_code.length>0) {
                     matchUser = this.users.find(u => u.user_code == user_code)
                  }

                  this.importUsersModal1.push({
                     _id: matchUser?matchUser._id:null,
                     user_code: user_code,
                     fullname: row.length>2?row[2]:'',
                     ngay_vaolam: row.length>3?row[3]:'',
                     congty: row.length>4?row[4]:'',
                     khoi: row.length>5?row[5]:'',
                     department: departmentObject?departmentObject._id:null,
                     departmentObject: departmentObject,
                     department_name: department_name,
                     chucvu: row.length>7?row[7]:'',
                     position: row.length>8?row[8]:'',
                     vanbans: vanban?[vanban]:[]
                  })
               }
            })
            if(this.importUsersModal1.length>0){
               this.dialogImportModal1 = true
            } else {
               alert('Lỗi! Không tìm thấy dữ liệu')
            }
         
         }
         // check ho so thieu
         if (this.importFileModal == 'modal2') {
            this.importUsersModal2 = []
            data.forEach((row,index) => {
               if(row.length>0&&index>0){
                  let user_code = row.length>1?row[1]:''
                  let matchUser = null
                  if (user_code.length>0) {
                     matchUser = this.users.find(u => u.user_code == user_code)
                  }

                  this.importUsersModal2.push({
                     _id: matchUser?matchUser._id:null,
                     user_code: user_code,
                     fullname: row.length>2?row[2]:'',
                     check_donxinviec: row.length>3&&row[3]?row[3].trim().length>0:false,
                     check_soyeulilich: row.length>4&&row[4]?row[4].trim().length>0:false,
                     check_giaykhamsuckhoe: row.length>5&&row[5]?row[5].trim().length>0:false,
                     check_giaykhaisinh: row.length>6&&row[6]?row[6].trim().length>0:false,
                     check_bangcap: row.length>7&&row[7]?row[7].trim().length>0:false,
                     check_cmnd: row.length>8&&row[8]?row[8].trim().length>0:false,
                     check_sohokhau: row.length>9&&row[9]?row[9].trim().length>0:false,
                     check_anh34: row.length>10&&row[10]?row[10].trim().length>0:false,
                  })
               }
            })
            if(this.importUsersModal2.length>0){
               this.dialogImportModal2 = true
            } else {
               alert('Lỗi! Không tìm thấy dữ liệu')
            }
         
         }
      },
      async saveModal1SelectedItem () {
         // console.log(`saveModal1SelectedItem`)
         // console.log(this.selectedUserModal1)
         this.onSaving = true
         this.resultContent = ''
         for (let i=0;i<this.selectedUserModal1.length;i++) {
            await this.saveUser(this.selectedUserModal1[i], true)
         }
         this.onSaving = false
         this.resultContent += 'Xong!!!\r\n'
         this.$emit('reloadUser')
      },
      async saveModal2SelectedItem () {
         this.onSaving = true
         this.resultContent = ''
         for (let i=0;i<this.selectedUserModal2.length;i++) {
            await this.saveUser(this.selectedUserModal2[i], true)
         }
         this.onSaving = false
         this.resultContent += 'Xong!!!\r\n'
         this.$emit('reloadUser')
      },
      async saveUser(user, allowCreateNew) {
         if(!user) return
         if(user._id) {
            await this.updateUser(user)
         } else {
            if(allowCreateNew) {
               await this.createNewUser(user)
            }
         }
      },
      async updateUser (user) {
         let path = this.$root.apiUser + "/users/update/" + user._id
         try {
            let res = await this.axios.put(path, user, { headers: { "x-auth": this.token } })
            if(res.data.status == 'OK') {
               // console.log(`Cập nhật ${user.fullname} xong`)
               this.resultContent += `Cập nhật ${user.fullname} xong <br>`
            } else {
               // console.log(`Cập nhật ${user.fullname} lỗi`)
               this.resultContent += `Cập nhật ${user.fullname} LỖI <br>`
            }
         } catch (err) { console.log(`Err on update user ${user.fullname}`) }
      },
      async createNewUser(user) {
         let path = this.$root.apiUser + "/users/create"
         user.customer_id = this.$root.apiUserCustomId
         try {
            let res = await this.axios.post(path, user, { headers: { "x-auth": this.token } })
            if(res.data.status == 'OK') {
               // console.log(`Tạo mới ${user.fullname} xong`)
               this.resultContent += `Tạo mới ${user.fullname} xong <br>`
            } else {
               // console.log(`Tạo mới ${user.fullname} lỗi`)
               this.resultContent += `Tạo mới ${user.fullname} LỖI <br>`
            }
         } catch (err) { console.log(`Err on createNewUser ${user.fullname}`) }
      },
   }
}
</script>
